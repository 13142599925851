<template>
  <v-img
    id="video-share"
    max-height="100vh"
    :src="require(`@/assets/images/pages/background.jpg`)"
  >
    <div class="auth-wrapper auth-v1">
      <div class="auth-inner">
        <v-card
          class="d-flex justify-center align-center"
          color="transparent"
        >
          <video-player
            ref="refVideoPlayer"
            class="video-player-box"
            :options="playerOptions"
            :playsinline="true"
            custom-event-name="customstatechangedeventname"
            @timeupdate="onPlayerTimeupdate($event)"
            @canplay="onPlayerCanplay($event)"
            @canplaythrough="onPlayerCanplaythrough($event)"
            @ready="playerReadied"
          ></video-player>
        </v-card>
      </div>
    </div>
  </v-img>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import { ref, computed, onMounted } from '@vue/composition-api'
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
import { useRouter } from '@core/utils'
import { getVideoPlaybackType } from '@api'

export default {
  components: {
    videoPlayer,
  },
  setup() {
    const videoData = ref(null)
    const refVideo = ref(null)
    const refUsername = ref(null)
    const players = ref({})
    const logo = ref(require('@/assets/images/icons/logo.png'))
    const source = ref('https://static.smartisanos.cn/common/video/smartisan-tnt-jianguo.mp4')
    const initdone = ref(false)
    const cover = ref(
      'https://static.smartisanos.cn/pr/assets/images/smartisan-tnt-jianguo.190162f215c4f5f2aaabf38e5782b3af.jpg',
    )

    const playerOptions = ref({
      controls: true,
      responsive: true,
      breakpoints: true,
      fill: true,
      autoplay: true,
      muted: true,
      loop: true,
      language: 'en',
      playbackRates: [0.7, 1.0, 1.5, 2.0],
      sources: [],
      poster: 'https://fanaty-api-stage.s3.amazonaws.com/no-thumb.jpg',
      notSupportedMessage: 'Este video no está disponible temporalmente, intente nuevamente más tarde.',
      usingPlugin: true,
      start: 10,
      end: 15,
    })
    const { route } = useRouter()
    const refVideoPlayer = ref(null)
    const player = computed(() => refVideoPlayer.value.player)

    const onPlayerTimeupdate = auxPlayer => {
      if (auxPlayer.currentTime() >= 15 && videoData.value && videoData.value.video_type === 'H') {
        initdone.value = false
        auxPlayer.currentTime(10)
      }
    }

    const onPlayerCanplaythrough = auxPlayer => {
      if (!initdone.value && videoData.value && videoData.value.video_type === 'H') {
        auxPlayer.currentTime(10)
        initdone.value = true
      }
    }

    const playerReadied = auxPlayer => {
      if (!initdone.value && videoData.value && videoData.value.video_type === 'H') {
        auxPlayer.currentTime(10)
        initdone.value = true
      }
    }

    onMounted(async () => {
      refVideo.value = route.value.params.playback

      const response = await getVideoPlaybackType(refVideo.value, 'S', refUsername.value)
      videoData.value = response.data

      playerOptions.value.sources.push({
        type: 'video/mp4',
        src: videoData.value.video_link,
      })
    })

    return {
      videoData,
      refVideo,
      refUsername,
      players,
      logo,
      initdone,
      source,
      cover,

      onPlayerTimeupdate,
      onPlayerCanplaythrough,
      playerReadied,
      player,
      playerOptions,
      videoPlayer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
<style scope>
.video-player-box .video-js {
  width: 65vw !important;
  height: 56vh !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .video-player-box .video-js {
    width: 90vw !important;
    height: 56vh !important;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .video-player-box .video-js {
    width: 90vw !important;
    height: 56vh !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .video-player-box .video-js {
    width: 80vw !important;
    height: 82vh !important;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .video-player-box .video-js {
    width: 80vw !important;
    height: 64vh !important;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .video-player-box .video-js {
    width: 80vw !important;
    height: 64vh !important;
  }
}

#video-share {
  font-family: 'Open Sans' !important;
}
</style>
